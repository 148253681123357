import React, { useState } from "react";
import Section3 from "../sections/Section3";
import { motion, stagger, useScroll } from "framer-motion";
import Card from "../blocks/Card";
import img1 from "../media/illustrator/04-min.png";
import img2 from "../media/illustrator/05-min.png";
import img3 from "../media/illustrator/2-min.png";
import img4 from "../media/illustrator/8 (1)-min.png";
import img5 from "../media/illustrator/Bawari Basanti 2-min.png";
import img6 from "../media/illustrator/Blue-min.png";
import img7 from "../media/illustrator/Char lineupA-min.png";
import img8 from "../media/illustrator/For Insta 1-min.png";
import img9 from "../media/illustrator/For Insta 2-min.png";
import img10 from "../media/illustrator/Isha-min.png";
import img11 from "../media/illustrator/Orange-min.png";
import img12 from "../media/illustrator/Pooja and kids_square-min.png";
import img13 from "../media/illustrator/Red-min.png";

function Chrachterdesigns() {
  const Characterdata = [
    { image: img1, data: [] },
    { image: img2, data: [] },
    { image: img3, data: [] },
    { image: img4, data: [] },
    { image: img5, data: [] },
    { image: img6, data: [] },
    { image: img7, data: [] },
    { image: img8, data: [] },
    { image: img9, data: [] },
    { image: img10, data: [] },
    { image: img11, data: [] },
    { image: img12, data: [] },
    { image: img13, data: [] },
  ];
  return (
    <>
      <div className="col-12">
        <div className="container position-static">
          <div className="row m-0 py-5 my-lg-5">
            <div className="col-lg-10 col-12">
              <motion.div
                className="display-3 fontitalic d-md-block d-none"
                initial={{ opacity: 0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.5 }}
                viewport={{ once: true }}
              >
                Character Designs
              </motion.div>
              <motion.p
                className="fs-3 ps-1 py-3 fontitalic  d-md-block d-none"
                initial={{ opacity: 0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1 }}
                viewport={{ once: true }}
              >
                I bring a sharp critical thinking ability and a keen eye for
                detail to my work, ensuring that every website, product, and
                experience I create is not only visually appealing but also
                functionally robust. My approach combines creativity with
                analytical skills, allowing me to design compelling and
                user-friendly digital solutions.
              </motion.p>
              <motion.div
                className="display-5 fontitalic d-md-none d-block"
                initial={{ opacity: 0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.5 }}
                viewport={{ once: true }}
              >
                Character Designs
              </motion.div>
              <motion.p
                className="fs-6 ps-1 py-3 fontitalic  d-md-none d-block"
                initial={{ opacity: 0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1 }}
                viewport={{ once: true }}
              >
                I bring a sharp critical thinking ability and a keen eye for
                detail to my work, ensuring that every website, product, and
                experience I create is not only visually appealing but also
                functionally robust. My approach combines creativity with
                analytical skills, allowing me to design compelling and
                user-friendly digital solutions.
              </motion.p>
            </div>
            <motion.div
              className="row m-0 pt-lg-4 pt-0 position-relative"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 1,
                delay: 1,
              }}
            >
              {Characterdata.map((i, index) => {
                return <Card i={i} index={index} key={index} />;
              })}
            </motion.div>
          </div>
        </div>
      </div>
      <Section3 />
    </>
  );
}

export default Chrachterdesigns;
